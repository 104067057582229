import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Camera, { FACING_MODES, IMAGE_TYPES } from "react-html5-camera-photo";
import 'react-html5-camera-photo/build/css/index.css';
import back from "../../assets/Icons/Back-white.png"
import demoIcon from "../../assets/Icons/image-reference.png"
import teethListIcon from "../../assets/Icons/teeth-list-icon.png"
import flashOn from "../../assets/Icons/flash-on.png"
import flashOff from "../../assets/Icons/flash-off.png"
import DemoScreens from './demoScreens';
import ScDemoScreens from './scDemoScreen';
import Preview from './preview';
import "./index.css"
import Scsteps from './scSteps';
import PermissionPopup from './cameraPermissionPopup';
import { GetBrowserDetails } from '../../Utils/deviceDetails';
import { GetMobileOperatingSystem } from '../../Utils/getOS';
import { fontStyle, fontWeight, buttonStyle, companyBucket } from "../../Utils/tpscanStyle"
import FrontCameraError from './frontCameraError';

const ScShoot = (props) => {
    const { dentalOfficeName } = useParams();
    const [facialMode, setFacialMode] = useState(FACING_MODES.USER)
    const [frontCameraError, setFrontCameraError] = useState(false)
    const { activeImageSide, setToothPicker, quickScan, setImageSide } = props
    const navigate = useNavigate()    
    const [isDemo, setDemo] = useState(true)
    const [isCamera, setCamera] = useState(true)
    const [capturedImage, setCapturedImage] = useState()
    const [flashLight, setFlashLight] = useState(false)
    const [mediaStream, setStream] = useState(null)
    const [errorType, setErrorType] = useState({ isError: false, type: "ios" })
    const OS = GetMobileOperatingSystem()   // operating system

    useEffect(() => {
        // Clean-up function to reset flashlight state when component unmounts or user navigates away
        return () => {
            if (mediaStream && flashLight) {
                setFlashLight(false)
                torchMechanism(mediaStream, false); // Turn off flashlight when component unmounts
            }
        };
    }, [mediaStream]);

    const handleFrontCamera = () => {
        navigator.mediaDevices.enumerateDevices()
            .then(devices => {
                const videoDevices = devices.filter(device => device.kind === 'videoinput');

                const frontCamera = videoDevices.find(device => device.label.toLowerCase().includes('front'));
                if (frontCamera) {
                    setFacialMode(frontCamera.deviceId); // Set the deviceId directly
                } else {
                    setFacialMode(FACING_MODES.ENVIRONMENT); // Fallback to rear camera
                    setFrontCameraError(true)
                }
            })
            .catch(err => console.error("Error enumerating devices: ", err));
    }




    const handleTakePhoto = (dataUri) => {
        setCapturedImage(dataUri)
        flashLight && torchMechanism(mediaStream, false)
        setTimeout(() => {
            setCamera(false)
        }, 400)
    }

    const toggleFlash = () => {

        if (flashLight) {
            setFlashLight(false);
            torchMechanism(mediaStream, false);
        } else {
            setFlashLight(true);
            torchMechanism(mediaStream, true);
        }
    }



    const torchMechanism = (stream, flashLight) => {
        if (stream) {
            const track = stream.getVideoTracks()[0];
            if (track) {
                track.applyConstraints({
                    advanced: [{ torch: flashLight }]
                }).then(() => {
                    console.log("Torch constraint applied successfully");
                }).catch((e) => {
                    console.log("Failed to apply torch constraint:");
                });
            } else {
                console.warn("No video track found");
            }

        }


    };



    const cameraStarted = (stream) => {
        setStream(stream)
        handleFrontCamera()
        torchMechanism(stream, flashLight)
        setErrorType({ isError: false, type: "ios" })
    }

    const checkError = () => {
        const browser = GetBrowserDetails();

        if (browser.name === "Mobile Chrome") {
            navigator.permissions.query({ name: 'camera' }).then((res) => {
                if (res.state === "denied") {
                    if (OS === 'iOS') {
                        setErrorType({ isError: true, type: "ios" })
                    } else {
                        setErrorType({ isError: true, type: "android" })
                    }
                }
            }).catch((error) => {
                setErrorType({ isError: true, type: "ios" })
            })

        } else {
            setErrorType({ isError: true, type: "ios" })
        }

    }

    const OvuleShape = () => {
        return (
            <div className={` ovule-container w-full flex flex-col justify-center items-center`}>
                <div className={`relative ovule ovule-${activeImageSide} ${activeImageSide === 1 && isCamera && "ovule-4-reversed"}  `}> 
                <p
                    className={`absolute -bottom-20 w-full text-center text-base text-white my-3 ${activeImageSide === 1 && isCamera && "ransform rotate-180 "} `}
                    style={ { ...fontWeight, ...fontStyle }}>
                    {Scsteps[activeImageSide].title}
                </p>
                </div>
            </div>
        )
    }


    const TopLayer = () => {
        return (
            <div className='camera-top-layer-2 h-full absolute top-0 text-white w-full'>
                <div className='flex justify-between items-center h-13 px-2'>
                    <button type='button' onClick={() => {localStorage.getItem("isAutomated") ? window.history.back() : setToothPicker(true) }}> <img src={back} alt="back" className="w-8 h-8" /> </button>
                    <div className='pt-2'>
                        <button type='button' onClick={() => { setDemo(true) }} > <img src={demoIcon} alt="back" className="w-12 h-12 " /> </button>
                        {/* {!quickScan && <button type='button' onClick={() => { setToothPicker(true) }}> <img src={teethListIcon} alt="back" className="w-12 h-12 mx-2" /> </button>} */}
                    </div>
                </div>
                <p
                    className={`text-base mt-16 text-center ${activeImageSide === 1 && isCamera && "ransform rotate-180 "}`}
                    style={companyBucket == "done" ? { fontWeight: 400, ...fontStyle, } : { ...fontWeight, ...fontStyle, }}>
                    {isCamera ? "Tap anywhere to capture" : `Preview of the ${Scsteps[activeImageSide].title}`}
                </p>
            </div>
        )
    }


    return (
        <>
            {isDemo ? <ScDemoScreens activeImageSide={activeImageSide} setToothPicker={setToothPicker} setDemo={setDemo} setCamera={setCamera} quickScan={quickScan} /> :
                <div className='h-screen'>
                    {errorType.isError && <PermissionPopup info={errorType} setErrorType={setErrorType} />}
                    {
                        isCamera ?
                            <>
                                <Camera
                                    onTakePhotoAnimationDone={(dataUri) => { handleTakePhoto(dataUri) }}
                                    idealFacingMode={facialMode}
                                    isFullscreen={true}
                                    isMaxResolution={true}
                                    imageType={IMAGE_TYPES.JPG}
                                    isImageMirror={facialMode !== FACING_MODES.ENVIRONMENT}
                                    imageCompression={0.6}
                                    onCameraStart={cameraStarted}
                                    onCameraError={checkError}
                                    className="WebCam"
                                />
                                {!errorType.isError && frontCameraError && <FrontCameraError back={setFrontCameraError} />}
                                
                            </> : <Preview setImageSide={setImageSide} image={capturedImage} setCamera={setCamera} setToothPicker={setToothPicker} activeImageSide={activeImageSide} setDemo={setDemo} quickScan={quickScan} />
                    }
                    <OvuleShape />
                    <TopLayer />

                </div >
            }
        </>
    )
}

export default ScShoot